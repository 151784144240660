import React, { useState, useEffect } from 'react'
import './Employee.scss';
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import EmpDashboard from '../../components/EmployeeDashboard/EmpDashboard'

const Employee = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('click', closeSidebarOnClickOutside);
    return () => {
      document.removeEventListener('click', closeSidebarOnClickOutside);
    };
  }, [isSidebarVisible]);

  const toggleSidebar = () => {
    setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
  };

  const closeSidebarOnClickOutside = (e) => {
    if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
      setSidebarVisible(false);
    }
  };

  return (
    <div className='employee'>
      <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
        <div className="close-btn" onClick={toggleSidebar}>
          ✖
        </div>
        <Sidebar />
      </div>

      <div className="employeeContainer">
        <div className="navbarcontainer">
          <button className="hamburger-btn" onClick={toggleSidebar}>
            ☰
          </button>
          <Navbar />
        </div>
        <div className='empdashboard'>
          <EmpDashboard />
        </div>
      </div>
    </div>

  )
}

export default Employee