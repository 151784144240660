import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationGuard from './NavigationGuard'; // Import the NavigationGuard
import { SessionTimeoutProvider } from "./SessionTimeoutContext";
const Layout = () => {
    return (
        <>
            <NavigationGuard /> {/* Guard applied globally */}
            <SessionTimeoutProvider>
                <Outlet /> {/* Render the routed components */}
            </SessionTimeoutProvider>
        </>
    );
};

export default Layout;
