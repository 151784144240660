import React, { useState, useEffect } from 'react'
import './HomeInternet.scss';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import { getUser, getHomeInternetData, getAllUsersData } from '../../helper/helper'
import UploadHIModal from './UploadHIModal'

import Sidebar from '../../components/Sidebar/Sidebar'
import toast, { Toaster } from 'react-hot-toast';
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from "react-csv";

const HomeInternet = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
  const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
  const username = localStorage.getItem('username');
  const [startDate, setStartDate] = useState(firstDayOfMonthString);
  const [endDate, setEndDate] = useState(lastDayOfMonthString);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [userList, setuserList] = useState([])
  const [userdata, setUserdata] = useState([]);
  const [storesList, setStoresList] = useState([])
  const [filterEmp, setFilterEmp] = useState('');
  const [finalHIData, setFinalHIData] = useState([]);

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
  };

  const closeSidebarOnClickOutside = (e) => {
    if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
      setSidebarVisible(false);
    }
  };

  // Add event listener to handle clicks outside the sidebar
  useEffect(() => {
    document.addEventListener('click', closeSidebarOnClickOutside);
    return () => {
      document.removeEventListener('click', closeSidebarOnClickOutside);
    };
  }, [isSidebarVisible]);

  useEffect(() => {
    async function fetchUser() {
      let { data } = await getUser({ username: username });
      setUserDetail(data)
    }
    fetchUser();
  }, [username])

  useEffect(() => {
    async function fetchUser() {
      let { data } = await getAllUsersData();
      setuserList(data)
    }
    fetchUser();
  }, [username])


  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };



  const handleSearch = async () => {
    setIsLoading(true);
    const { data } = await getHomeInternetData({ startDate, endDate });
    console.log(data);

    if (userDetail?.role === 'manager') {
      let finalArray = []
      userDetail?.stores?.split(',').forEach(store => {
        userList.forEach(user => {
          let exist = user.stores.split(',').some(item => item === store)
          if (exist) {
            if (!finalArray.includes(user)) {
              finalArray.push(user)
            }
          }
        })
      })
      setUserdata(finalArray);
      let storesList = userDetail?.stores?.split(',')
      setStoresList(storesList)
      let employeeList = finalArray?.map(emp => emp.username);
      setFilterEmp(employeeList);

      let filterHI = []
      storesList.forEach(store => {
        data.forEach(obj => {
          const { storeInvoice } = obj;
          if (storeInvoice === store) {
            filterHI.push(obj)
          }
        })
      })
      setFinalHIData(filterHI)
    } else if (userDetail?.role === 'admin') {
      setUserdata(data);
      let filterStores = []
      let storesList = data?.map(store => store.storeInvoice);
      storesList?.forEach(val => {
        let isExist = filterStores.some(store => store === val);
        if (!isExist) filterStores.push(val);
      })
      setStoresList(filterStores)
      let filterEmployee = []
      let employeeList = data?.map(emp => emp.user);
      employeeList?.forEach(val => {
        let isExist = filterEmployee.some(employee => employee === val);
        if (!isExist) filterEmployee.push(val);
      })
      setFilterEmp(filterEmployee);
      setFinalHIData(data)
    } else if (userDetail?.role === 'employee') {
      let filterHI = []
      data.forEach(obj => {
        if (obj.user === userDetail.username) {
          filterHI.push(obj)
        }
      })
      let storesList = userDetail?.stores?.split(',')
      setFinalHIData(filterHI)
      setStoresList(storesList)
    }
  }

  if (isLoading && apiData?.length > 0) {
    setIsLoading(false);
  }

  const rows = finalHIData?.map((row) => ({
    _id: row._id,
    customerName: row.customerName,
    phoneNumber: row.phoneNumber,
    clientNumber: row.clientNumber,
    internetPlanName: row.internetPlanName,
    monthlyCost: row.monthlyCost,
    regularMonthlyPayment: row.regularMonthlyPayment,
    discount: row.discount,
    saleInvoice: row.saleInvoice,
    tenderedDate: row.tenderedDate,
    time: row.time,
    user: row.user
  }))

  const columns = [
    /* { field: '_id', headerName: 'Id', width: 50 }, */
    { field: 'customerName', headerName: 'Customer Name', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'clientNumber', headerName: 'Client Number', width: 150 },
    { field: 'internetPlanName', headerName: 'Plan Name', width: 150 },
    { field: 'regularMonthlyPayment', headerName: 'Original Cost', width: 150 },
    { field: 'discount', headerName: 'Discount', width: 150 },
    { field: 'monthlyCost', headerName: 'Monthly Cost', width: 150 },
    { field: 'saleInvoice', headerName: 'Sales Invoice', width: 130, },
    { field: 'tenderedDate', headerName: 'Tendered Date', width: 130 },
    { field: 'time', headerName: 'Time', width: 100 },
    { field: 'user', headerName: 'User', width: 300 }
  ]
  return (
    <div className="hi-main">
      <Toaster position='top-center' reverseOrder={false} toastOptions={{ duration: 1000 }} gutter={4}></Toaster>
      <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
        <div className="close-btn" onClick={toggleSidebar}>
          ✖
        </div>
        {userDetail?.role?.length > 0 && userDetail?.role === "admin" ? <AdminSidebar /> : <Sidebar />}
      </div>

      <div className="hi-container">
        <div className="navbarcontainer">
          <button className="hamburger-btn" onClick={toggleSidebar}>
            ☰
          </button>
          <Navbar />
        </div>
        <div className="hi-block-container">
          {/*  <div className='filter-menu-list'> */}
          <div className='filter-menu'>
            <label htmlFor="startDate">From:</label>
            <input
              type="date"
              id="startDate"
              className='filter-datemenu-input'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label htmlFor="endDate">To:</label>
            <input
              type="date"
              id="endDate"
              className='filter-datemenu-input'
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button onClick={handleSearch} >
              Search
            </button>
            <button onClick={openModal}>
              Upload
            </button>
            <CSVLink data={finalHIData ? finalHIData : ''} className='edit-btn'>Download</CSVLink>
            {/* <div className="topbuttons">
              <button onClick={() => deleteCisData(arrIds)} className='edit-btn' type='submit'>Delete</button>

            </div> */}

            <UploadHIModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
            />
          </div>

          <div className='bot'>

            <div className="tablecontainer">
              <div className="bottomtable" style={{ height: 350, width: '100%' }}>
                <DataGrid
                  getRowId={(row) => row._id}
                  rows={rows ? rows : []}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  disableRowSelectionOnClick
                /* onRowSelectionModelChange={(ids) => {
                  setArrIds(ids)
                }} */
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default HomeInternet