import React, { useState, useRef, useEffect } from 'react'
import { submitCisData, getUser, getManualSkuList, submitAR, submitAROnlyAccessory } from '../../helper/helper'
import toast, { Toaster } from 'react-hot-toast';
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import './CisUpload.scss'
import { Button } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CisModal from './CisModal';

const Home = () => {
    const cisRef = useRef(null);
    const apRef = useRef(null);
    const rqRef = useRef(null);
    const rqRefOnlyAccessory = useRef(null);
    const rqRefCISForm = useRef(null);
    const username = localStorage.getItem('username');
    const [result, setResult] = useState("");
    const [apresult, setApresult] = useState("");
    const [rqresult, setRqresult] = useState("");
    const [rqCISFormresult, setRqCISFormresult] = useState("");
    const [rqAccessoryResult, setRqAccessoryResult] = useState("")
    const [role, setRole] = useState('')
    const [IsSignaturesPresent, setIsSignaturesPresent] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
    };

    const closeSidebarOnClickOutside = (e) => {
        if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
            setSidebarVisible(false);
        }
    };

    // Add event listener to handle clicks outside the sidebar
    useEffect(() => {
        document.addEventListener('click', closeSidebarOnClickOutside);
        return () => {
            document.removeEventListener('click', closeSidebarOnClickOutside);
        };
    }, [isSidebarVisible]);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        let dataUser = getUser({ username: username });
        dataUser.then(res => {
            let apiData = res.data;
            setRole(apiData?.role)
        })
    }, [username])

    /* Cis  */
    async function readCisFile(event) {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            const data = await reader.result;
            await loadPDF(data);
        }

        async function loadPDF(data) {
            const [{ getDocument }] = await Promise.all([
                import("pdfjs-dist"),
                import("pdfjs-dist/build/pdf.worker.entry"),
            ]);
            const pdf = await getDocument(data).promise;
            const metaData = await pdf.getMetadata();
            setIsSignaturesPresent(metaData.info.IsSignaturesPresent);
            await pdfToText(pdf)
        }


        async function pdfToText(pdf) {
            let result = "";
            for (let i = 1; i <= pdf.numPages; i++) {
                result += await getPageText(pdf, i);
            }
            setResult(result);
        }

        async function getPageText(pdf, pageNum = 1) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent()
            const items = textContent.items.filter((item) => item.str.trim());
            const finalData = items.map(({ str }) => str).join(" ");
            return finalData
        }
    }

    /* AP */
    async function readApFile(event) {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            const data = await reader.result;
            await loadPDF(data);
        }

        async function loadPDF(data) {
            const [{ getDocument }] = await Promise.all([
                import("pdfjs-dist"),
                import("pdfjs-dist/build/pdf.worker.entry"),
            ]);
            const pdf = await getDocument(data).promise;
            await pdfToText(pdf)
        }

        async function pdfToText(pdf) {
            let result = "";
            for (let i = 1; i <= pdf.numPages; i++) {
                result += await getPageText(pdf, i);
            }
            setApresult(result);
        }

        async function getPageText(pdf, pageNum = 1) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent()
            const items = textContent.items.filter((item) => item.str.trim());
            const finalData = items.map(({ str }) => str).join(" ");
            return finalData
        }
    }
    /* RQ4 */
    async function readRqFile(event) {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            const data = await reader.result;
            await loadPDF(data);
        }

        async function loadPDF(data) {

            const [{ getDocument }] = await Promise.all([
                import("pdfjs-dist"),
                import("pdfjs-dist/build/pdf.worker.entry"),
            ]);
            const pdf = await getDocument(data).promise;
            await pdfToText(pdf)
        }

        async function pdfToText(pdf) {
            let result = "";
            for (let i = 1; i <= pdf.numPages; i++) {
                result += await getPageText(pdf, i);
            }
            setRqresult(result);
        }

        async function getPageText(pdf, pageNum = 1) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent()
            const items = textContent.items.filter((item) => item.str.trim());
            const finalData = items.map(({ str }) => str).join(" ");
            return finalData
        }
    }
    /* RQ4 for only accessory */
    async function readRqOnlyAccessoryFile(event) {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            const data = await reader.result;
            await loadPDF(data);
        }

        async function loadPDF(data) {
            const [{ getDocument }] = await Promise.all([
                import("pdfjs-dist"),
                import("pdfjs-dist/build/pdf.worker.entry"),
            ]);
            const pdf = await getDocument(data).promise;
            await pdfToText(pdf)
        }

        async function pdfToText(pdf) {
            let result = "";
            for (let i = 1; i <= pdf.numPages; i++) {
                result += await getPageText(pdf, i);
            }
            setRqAccessoryResult(result);
        }

        async function getPageText(pdf, pageNum = 1) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent()
            const items = textContent.items.filter((item) => item.str.trim());
            const finalData = items.map(({ str }) => str).join(" ");
            return finalData
        }
    }

    async function readRQCISForm(event) {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            const data = await reader.result;
            await loadPDF(data);
        }

        async function loadPDF(data) {

            const [{ getDocument }] = await Promise.all([
                import("pdfjs-dist"),
                import("pdfjs-dist/build/pdf.worker.entry"),
            ]);
            const pdf = await getDocument(data).promise;
            await pdfToText(pdf)
        }

        async function pdfToText(pdf) {
            let result = "";
            for (let i = 1; i <= pdf.numPages; i++) {
                result += await getPageText(pdf, i);
            }
            setRqCISFormresult(result);
        }

        async function getPageText(pdf, pageNum = 1) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent()
            const items = textContent.items.filter((item) => item.str.trim());
            const finalData = items.map(({ str }) => str).join(" ");
            return finalData
        }
    }

    function dateConversion(date) {
        // Parse the input date string using the Date constructor
        const parsedDate = new Date(date);
        // Format the parsed date in the "YYYY-MM-DD" format
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(parsedDate.getDate()).padStart(2, "0");

        // Create the formatted date string
        const formattedDateStr = `${year}-${month}-${day}`;
        return formattedDateStr
    }

    async function cisDataExtraction(result) {

        const addonList = [
            "1500 ILD Minutes at $5 per mo. to 6 Destinations $5.00",
            "Unlimited Long Distance - USA $5.00",
            "Big Gig World Saver - Freedom Nationwide $5.00",
            "World Traveller $8.00",
            "1GB Freedom Nationwide Add-On(FS) $15.00",
            "2GB Freedom Nationwide Add-On(FS) $30.00",
            "3GB Freedom Nationwide Add-On(FS) $45.00",
            "ILD - India(250 minutes) $10.00",
            "ILD - India(250 minutes)",
            "ILD - India(1000 Minutes) $15.00",
            "ILD - India(1000 Minutes)",
            "Visual Voicemail $4.00",
            "ILD - China or Hong Kong (250 minutes) $10.00",
            "ILD - China or Hong Kong (1000 minutes) $15.00",
            "Phone Protection Plan 9 $9.00",
            "Phone Protection Plan $9.00",
            "Voicemail+ $5.00",
        ]

        const pdfText = result;

        let matchedKeywords = "";
        for (const keyword of addonList) {
            const regex = new RegExp(keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
            if (regex.test(pdfText)) {
                matchedKeywords += keyword + ","; // Add the matched keyword to the string
            }
        }

        let values = {};
        let DFF = "";
        if (result.includes("To be confirmed upon Service activation")) {
            DFF = "DFF";
        }
        // postpaid with phone
        if (/Plan\sName(.*?)Price/i.exec(result)[1].trim().slice(0, 6) !== "Tablet" && /Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {
            values = {
                customerName: /Name(.*?)Commitment/i.exec(result)[1].trim(),
                accountNum: /Account\sNumber(.*?)Phone\sNumber/i.exec(result)[1].trim(),
                phoneNum: /Phone\sNumber(.*?)At/i.exec(result)[1].trim(),
                tempPhoneNum: /Temporary\sPhone\sNo.†(.*?)Deposit\sto\sbe/i.exec(result)[1].trim(),
                simNumber: DFF ? DFF : /SIM\sNumber(.*?)Agreement\sStart/i.exec(result)[1].trim(),
                imei: DFF ? DFF : /IMEI(.*?)Total\sMyTab/i.exec(result)[1].trim(),
                amountYouPaid: /Amount\syou\spaid(.*?)Monthly\sMyTab\sCharge/i.exec(result)[1].trim().slice(1),
                connectionType: /Type(.*?)Deposit/i.exec(result)[1].trim(),
                activationDate: dateConversion(/Agreement\sStart\sDate(.*?)Billing/i.exec(result)[1].trim()),
                phoneMake: /Model(.*?)MyTab/i.exec(result)[1].trim(),
                myBalanceOwing: /MyTab(.*?)Phone\sProtection\sPlan/i.exec(result)[1].trim().slice(-8),
                planName: /Plan\sName(.*?)Price/i.exec(result)[1].trim(),
                planPrice: /Charges\sRate\sPlan(.*?).00/i.exec(result)[1].trim(),
                oneTimeCharges: /non-refundable\sConnection\sFee\s(.*?)will\sbe\scharged/i.exec(result)[1].trim().slice(-6),
                addons: matchedKeywords,
                storeId: /Store\sID(.*?)Specialist/i.exec(result)[1].trim(),
                specialistId: /Specialist\sID(.*?)Services/i.exec(result)[1].trim(),
                deposit: /Deposit(.*?)Deposit/i.exec(result)[1].trim().slice(2),
                retailPrice: /Retail\sPrice(.*?)Total\sMyTab\sSavings/i.exec(result)[1].trim(),
                activatedPromotion: /Activated\sPromotion(.*?)Rate\sPlan\sDetails/i.exec(result)[1].trim().slice(4),
                email: /Email\sAddress(.*?)Alternate\sPhone\sNumber/i.exec(result)[1].trim(),
                phoneProtectionPlan: /Phone\sProtection\sPlan(.*?)Total\sMyTab\sCharge/i.exec(result)[1].trim().slice(3),

            }

            return values;
        }
        // postpaid with tablet
        else if (/Plan\sName(.*?)Price/i.exec(result)[1].trim().slice(0, 6) === "Tablet" && /Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {
            values = {
                customerName: /Name(.*?)Commitment/i.exec(result)[1].trim(),
                accountNum: /Account\sNumber(.*?)Phone\sNumber/i.exec(result)[1].trim(),
                phoneNum: /Phone\sNumber(.*?)At/i.exec(result)[1].trim(),
                tempPhoneNum: /Temporary\sPhone\sNo.†(.*?)Deposit\sto\sbe/i.exec(result)[1].trim(),
                simNumber: DFF ? DFF : /SIM\sNumber(.*?)Agreement\sStart/i.exec(result)[1].trim(),
                imei: DFF ? DFF : /IMEI(.*?)Total\sMyTab/i.exec(result)[1].trim(),
                amountYouPaid: /Amount\syou\spaid(.*?)Monthly\sMyTab\sCharge/i.exec(result)[1].trim().slice(1),
                connectionType: /Type(.*?)Deposit/i.exec(result)[1].trim(),
                activationDate: dateConversion(/Agreement\sStart\sDate(.*?)Billing/i.exec(result)[1].trim()),
                phoneMake: /Model(.*?)MyTab/i.exec(result)[1].trim(),
                myBalanceOwing: /MyTab(.*?)Phone\sProtection\sPlan/i.exec(result)[1].trim().slice(-8),
                planName: /Plan\sName(.*?)Price/i.exec(result)[1].trim(),
                planPrice: /Charges\sRate\sPlan(.*?).00/i.exec(result)[1].trim(),
                oneTimeCharges: /non-refundable\sConnection\sFee\s(.*?)will\sbe\scharged/i.exec(result)[1].trim().slice(-6),
                addons: matchedKeywords,
                storeId: /Store\sID(.*?)Specialist/i.exec(result)[1].trim(),
                specialistId: /Specialist\sID(.*?)Services/i.exec(result)[1].trim(),
                deposit: /Deposit(.*?)Deposit/i.exec(result)[1].trim().slice(2),
                retailPrice: /Retail\sPrice(.*?)Total\sMyTab\sSavings/i.exec(result)[1].trim(),
                activatedPromotion: /Activated\sPromotion(.*?)Rate\sPlan\sDetails/i.exec(result)[1].trim().slice(4),
                email: /Email\sAddress(.*?)Alternate\sPhone\sNumber/i.exec(result)[1].trim(),
                phoneProtectionPlan: /Phone\sProtection\sPlan(.*?)Total\sMyTab\sCharge/i.exec(result)[1].trim().slice(3),

            }

            return values;
        }
        //hup
        else if (/Plan\sName(.*?)Price/i.exec(result)[1].trim().slice(0, 6) !== "Tablet" && /Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {
            values = {
                customerName: /Name(.*?)Commitment/i.exec(result)[1].trim(),
                accountNum: /Account\sNumber(.*?)Phone\sNumber/i.exec(result)[1].trim(),
                phoneNum: /Phone\sNumber(.*?)At/i.exec(result)[1].trim(),
                tempPhoneNum: /Temporary\sPhone\sNo.†(.*?)Deposit\sto\sbe/i.exec(result)[1].trim(),
                simNumber: DFF ? "N/A" : /SIM\sNumber(.*?)Agreement\sStart/i.exec(result)[1].trim(),
                imei: DFF ? DFF : /IMEI(.*?)Total\sMyTab/i.exec(result)[1].trim(),
                amountYouPaid: /Amount\syou\spaid(.*?)Monthly\sMyTab\sCharge/i.exec(result)[1].trim().slice(1).trim(),
                connectionType: /Type(.*?)Deposit/i.exec(result)[1].trim(),
                activationDate: dateConversion(/Agreement\sStart\sDate(.*?)Billing/i.exec(result)[1].trim()),
                phoneMake: /Model(.*?)MyTab/i.exec(result)[1].trim(),
                myBalanceOwing: /MyTab(.*?)Phone\sProtection\sPlan/i.exec(result)[1].trim().slice(-8).trim(),
                planName: /Plan\sName(.*?)Price/i.exec(result)[1].trim(),
                planPrice: /Charges\sRate\sPlan(.*?).00/i.exec(result)[1].trim(),
                oneTimeCharges: /non-refundable\sConnection\sFee\s(.*?)will\sbe\scharged/i.exec(result)[1].trim().slice(-6).trim(),
                addons: matchedKeywords,
                storeId: /Store\sID(.*?)Specialist/i.exec(result)[1].trim(),
                specialistId: /Specialist\sID(.*?)Services/i.exec(result)[1].trim(),
                deposit: /Deposit(.*?)Deposit/i.exec(result)[1].trim().slice(2).trim(),
                retailPrice: /Retail\sPrice(.*?)Total\sMyTab\sSavings/i.exec(result)[1].trim(),
                activatedPromotion: /Activated\sPromotion(.*?)Rate\sPlan\sDetails/i.exec(result)[1].trim().slice(4).trim(),
                email: /Email\sAddress(.*?)Alternate\sPhone\sNumber/i.exec(result)[1].trim(),
                phoneProtectionPlan: /Phone\sProtection\sPlan(.*?)Total\sMyTab\sCharge/i.exec(result)[1].trim().slice(3).trim(),
            }
            return values;
        }
        //hup with tablet
        else if (/Plan\sName(.*?)Price/i.exec(result)[1].trim().slice(0, 6) === "Tablet" && /Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {
            values = {
                customerName: /Name(.*?)Commitment/i.exec(result)[1].trim(),
                accountNum: /Account\sNumber(.*?)Phone\sNumber/i.exec(result)[1].trim(),
                phoneNum: /Phone\sNumber(.*?)At/i.exec(result)[1].trim(),
                tempPhoneNum: /Temporary\sPhone\sNo.†(.*?)Deposit\sto\sbe/i.exec(result)[1].trim(),
                simNumber: DFF ? DFF : /SIM\sNumber(.*?)Agreement\sStart/i.exec(result)[1].trim(),
                imei: DFF ? DFF : /IMEI(.*?)Total\sMyTab/i.exec(result)[1].trim(),
                amountYouPaid: /Amount\syou\spaid(.*?)Monthly\sMyTab\sCharge/i.exec(result)[1].trim().slice(1).trim(),
                connectionType: /Type(.*?)Deposit/i.exec(result)[1].trim(),
                activationDate: dateConversion(/Agreement\sStart\sDate(.*?)Billing/i.exec(result)[1].trim()),
                phoneMake: /Model(.*?)MyTab/i.exec(result)[1].trim(),
                myBalanceOwing: /MyTab(.*?)Phone\sProtection\sPlan/i.exec(result)[1].trim().slice(-8).trim(),
                planName: /Plan\sName(.*?)Price/i.exec(result)[1].trim(),
                planPrice: /Charges\sRate\sPlan(.*?).00/i.exec(result)[1].trim(),
                oneTimeCharges: /non-refundable\sConnection\sFee\s(.*?)will\sbe\scharged/i.exec(result)[1].trim().slice(-6).trim(),
                addons: matchedKeywords,
                storeId: /Store\sID(.*?)Specialist/i.exec(result)[1].trim(),
                specialistId: /Specialist\sID(.*?)Services/i.exec(result)[1].trim(),
                deposit: /Deposit(.*?)Deposit/i.exec(result)[1].trim().slice(2).trim(),
                retailPrice: /Retail\sPrice(.*?)Total\sMyTab\sSavings/i.exec(result)[1].trim(),
                activatedPromotion: /Activated\sPromotion(.*?)Rate\sPlan\sDetails/i.exec(result)[1].trim().slice(4).trim(),
                email: /Email\sAddress(.*?)Alternate\sPhone\sNumber/i.exec(result)[1].trim(),
                phoneProtectionPlan: /Phone\sProtection\sPlan(.*?)Total\sMyTab\sCharge/i.exec(result)[1].trim().slice(3).trim(),
            }
            return values;
        }

        else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Prepaid") {
            values = {
                customerName: /Name(.*?)Commitment/i.exec(result)[1].trim(),
                accountNum: /Account\sNumber(.*?)Phone\sNumber/i.exec(result)[1].trim(),
                phoneNum: /Phone\sNumber(.*?)Your\sBilling/i.exec(result)[1].trim(),
                tempPhoneNum: /Temporary\sPhone\sNo.†(.*?)Deposit\sto\sbe/i.exec(result)[1].trim(),
                simNumber: /SIM\sNumber(.*?)Agreement\sStart/i.exec(result)[1].trim(),
                imei: /IMEI(.*?)Phone\sProtection/i.exec(result)[1].trim(),
                amountYouPaid: /Amount\syou\spaid(.*?)MyTab\sCharge/i.exec(result)[1].trim().slice(1).trim(),
                connectionType: /Type(.*?)Deposit/i.exec(result)[1].trim(),
                activationDate: dateConversion(/Agreement\sStart\sDate(.*?)Billing/i.exec(result)[1].trim()),
                phoneMake: /Model(.*?)Phone\sProtection/i.exec(result)[1].trim(),
                myBalanceOwing: /MyTab\sCharge(.*?)MyTab\sDevice\sSavings/i.exec(result)[1].trim().slice(-8).trim(),
                planName: /Plan\sName(.*?)Price/i.exec(result)[1].trim(),
                planPrice: /Charges\sRate\sPlan(.*?).00/i.exec(result)[1].trim(),
                oneTimeCharges: /non-refundable\sConnection\sFee\s(.*?)will\sbe\scharged/i.exec(result)[1].trim().slice(-6).trim(),
                addons: matchedKeywords,
                storeId: /Store\sID(.*?)Specialist/i.exec(result)[1].trim(),
                specialistId: /Specialist\sID(.*?)Services/i.exec(result)[1].trim(),
                deposit: /Deposit(.*?)Deposit/i.exec(result)[1].trim().slice(2).trim(),
                retailPrice: /Retail\sPrice(.*?)TradeUp\sReturn\sValue/i.exec(result)[1].trim(),
                activatedPromotion: /Activated\sPromotion(.*?)Rate\sPlan\sDetails/i.exec(result)[1].trim().slice(4).trim(),
                email: /Email\sAddress(.*?)Alternate\sPhone\sNumber/i.exec(result)[1].trim(),
                phoneProtectionPlan: /Phone\sProtection\sPlan(.*?)Retail\sPrice/i.exec(result)[1].trim().slice(3).trim(),

            }
            return values;
            //post without phone
        } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() === "Bring Your Own Phone") {

            values = {
                customerName: /Name(.*?)Commitment/i.exec(result)[1].trim(),
                accountNum: /Account\sNumber(.*?)Phone\sNumber/i.exec(result)[1].trim(),
                phoneNum: /Phone\sNumber(.*?)Your\sBilling/i.exec(result)[1].trim(),
                tempPhoneNum: /Temporary\sPhone\sNo.†(.*?)Deposit\sto\sbe/i.exec(result)[1].trim(),
                simNumber: /SIM\sNumber(.*?)Agreement\sStart/i.exec(result)[1].trim(),
                imei: /IMEI(.*?)Total\sMyTab/i.exec(result)[1].trim(),
                amountYouPaid: /Amount\syou\spaid(.*?)Monthly\sMyTab\sCharge/i.exec(result)[1].trim().slice(1).trim(),
                connectionType: /Type(.*?)Deposit/i.exec(result)[1].trim(),
                activationDate: dateConversion(/Agreement\sStart\sDate(.*?)Billing/i.exec(result)[1].trim()),
                phoneMake: /Model(.*?)MyTab/i.exec(result)[1].trim(),
                myBalanceOwing: /MyTab\s(.*?)Phone\sProtection\sPlan/i.exec(result)[1].trim().slice(-3).trim(),
                planName: /Plan\sName(.*?)Price/i.exec(result)[1].trim(),
                planPrice: /Charges\sRate\sPlan(.*?).00/i.exec(result)[1].trim(),
                oneTimeCharges: /non-refundable\sConnection\sFee\s(.*?)will\sbe\scharged/i.exec(result)[1].trim().slice(-6).trim(),
                addons: matchedKeywords,
                storeId: /Store\sID(.*?)Specialist/i.exec(result)[1].trim(),
                specialistId: /Specialist\sID(.*?)Services/i.exec(result)[1].trim(),
                deposit: /Deposit(.*?)Deposit/i.exec(result)[1].trim().slice(2).trim(),
                retailPrice: /Retail\sPrice(.*?)Total\sMyTab\sSavings/i.exec(result)[1].trim(),
                activatedPromotion: /Activated\sPromotion(.*?)Rate\sPlan\sDetails/i.exec(result)[1].trim().slice(4).trim(),
                email: /Email\sAddress(.*?)Alternate\sPhone\sNumber/i.exec(result)[1].trim(),
                phoneProtectionPlan: /Phone\sProtection\sPlan(.*?)Total\sMyTab\sCharge/i.exec(result)[1].trim().slice(3).trim(),
            }

            return values

        }

    }

    async function autoPayDataExtraction(apresult, result) {

        let values = {};
        const regex = /successfully for Pre - Authorized Payments/g;
        const matches = apresult.match(regex);

        if (matches) {
            if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {

                values = {
                    autoPayPost: matches[0],
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPre: "N/A",
                };
                return values;
            } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {
                values = {
                    autoPayPost: "N/A",
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPre: "N/A",
                };
                return values;
            } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Prepaid") {

                values = {
                    autoPayPre: matches[0],
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPost: "N/A",
                };
                return values;
            } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() === "Bring Your Own Phone") {
                values = {
                    autoPayPost: matches[0],
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPre: "N/A",
                };
                return values;
            }

        } else {
            if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {

                values = {
                    autoPayPost: /Pay\sAfter(.*?)Your\scredit\scard/i.exec(apresult)[1].trim().slice(-3).trim(),
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPre: "N/A",
                };
                return values;
            } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {
                values = {
                    autoPayPost: "N/A",
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPre: "N/A",
                };
                return values;
            } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Prepaid") {

                values = {
                    autoPayPre: /has\sbeen\sregistered(.*?)for\sPre/i.exec(apresult)[1].trim(),
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPost: "N/A",
                };
                return values;
            } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() === "Bring Your Own Phone") {
                console.log(apresult)
                values = {
                    autoPayPost: /Pay\sAfter(.*?)Your\scredit\scard/i.exec(apresult)[1].trim().slice(-3).trim(),
                    customerName: /Customer\sName(.*?)Account\sNumber/i.exec(apresult)[1].trim().slice(2).trim(),
                    accountNum: /Account\sNumber(.*?)Card\sRegistered/i.exec(apresult)[1].trim().slice(2).trim(),
                    autoPayPre: "N/A",
                };
                return values;
            }
        }

    }

    async function rq4DataExtraction(rqresult, result) {

        let values = {};
        const numberPattern = /\d+/g;
        let DFF = ""
        if (rqresult.includes("ACCANS003120")) {
            DFF = "DFF"
        }
        const matches = rqresult.match(numberPattern);

        let rqImei = '';
        if (matches) {
            for (const match of matches) {
                if (match.length === 15) { // Check if the matched number has 15 digits
                    rqImei = match // Output: 351024743679099

                }
            }
        } else {
            console.log("Number not found in the input text");
        }
        function saleInvoiceExtraction(rqresult) {
            const pattern = /Sale Invoice : ([A-Z0-9]+)/;

            // Use the RegExp.exec() method to find the match
            const match = pattern.exec(rqresult);

            // Extract the desired text (if a match is found)
            let extractedText = '';
            if (match && match[1]) {
                extractedText = match[1];
                // Output: VYAD2IN134752
            } else {
                console.log("No match found");
            }
            return extractedText
        }
        function accountNumberExtraction(input) {
            const pattern = /(DBA|DBC|DBB)\d{11}/;

            const match = pattern.exec(input);


            return match ? match[0] : '';
        }

        function simExtraction(input) {

            const pattern = /Contract\s#(.*?)DB/;

            const match = pattern.exec(input);


            return match ? match[1].trim().slice(-19).trim() : '';
        }
        const timePattern = /\b(0?[1-9]|1[0-2]):[0-5][0-9]\s?[APap][Mm]\b/g;

        // Use the match method to find all matches
        const times = rqresult.match(timePattern);

        if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {


            values = {
                storeInvoice: /Sale\sInvoice(.*?)Tendered\sOn/i.exec(rqresult)[1].trim().slice(2, 9).trim(),
                saleInvoice: saleInvoiceExtraction(rqresult),
                tenderedDate: /\d{2}-[A-Za-z]{3}-\d{4}/.exec(rqresult)[0],
                time: times[0],
                imei: rqImei ? rqImei : "DFF",
                accountNum: DFF ? DFF : accountNumberExtraction(rqresult),
                simNumber: simExtraction(rqresult),
            }

            return values;
        } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Model(.*?)MyTab/i.exec(result)[1].trim() !== "Bring Your Own Phone") {
            values = {
                storeInvoice: /Sale\sInvoice(.*?)Tendered\sOn/i.exec(rqresult)[1].trim().slice(2, 9).trim(),
                saleInvoice: saleInvoiceExtraction(rqresult),
                tenderedDate: /\d{2}-[A-Za-z]{3}-\d{4}/.exec(rqresult)[0],
                time: times[0],
                imei: rqImei ? rqImei : "DFF",
                accountNum: DFF ? DFF : /(DBA|DBC|DBB)\d{11}/.exec(rqresult)[0],
                simNumber: "N/A",
            }

            return values;
        } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Prepaid") {

            values = {
                storeInvoice: /Sale\sInvoice(.*?)Tendered\sOn/i.exec(rqresult)[1].trim().slice(2, 9).trim(),
                saleInvoice: saleInvoiceExtraction(rqresult),
                tenderedDate: /\d{2}-[A-Za-z]{3}-\d{4}/.exec(rqresult)[0],
                time: times[0],
                accountNum: DFF ? "" : /(DBA|DBC|DBB)\d{11}/.exec(rqresult)[0],
                simNumber: DFF ? "" : /Contract\s#(.*?)DB/i.exec(rqresult)[1].trim().slice(-19).trim(),
                imei: "N/A",
            }
            return values;
        } else if (/Type(.*?)Deposit/i.exec(result)[1].trim() === "Postpaid" && /Initial(.*?)Limit/i.exec(result)[1].trim() === "Credit" && /Model(.*?)MyTab/i.exec(result)[1].trim() === "Bring Your Own Phone") {
            values = {
                storeInvoice: /Sale\sInvoice(.*?)Tendered\sOn/i.exec(rqresult)[1].trim().slice(2, 9).trim(),
                saleInvoice: saleInvoiceExtraction(rqresult),
                tenderedDate: /\d{2}-[A-Za-z]{3}-\d{4}/.exec(rqresult)[0],
                time: times[0],
                accountNum: DFF ? DFF : accountNumberExtraction(rqresult),
                simNumber: DFF ? DFF : simExtraction(rqresult),
                imei: "N/A",
            }

            return values;
        }

    }

    async function rq4DataExtractionOnlyAccessory(rqresult) {
        function saleInvoiceExtraction(rqresult) {
            const pattern = /Sale Invoice : ([A-Z0-9]+)/;
            const match = pattern.exec(rqresult);
            let extractedText = '';
            if (match && match[1]) {
                extractedText = match[1];
            } else {
                console.log("No match found");
            }
            return extractedText
        }
        function checkStringForWords(str) {
            // Regular expression to match "Postpaid" or "postpaid"
            const postpaidRegex = /postpaid/i; // 'i' makes it case insensitive

            // Regular expression to match "Hardware Upgrade"
            const hardwareUpgradeRegex = /Hardware Upgrade/;

            // Check if both patterns are present in the string
            return postpaidRegex.test(str) && hardwareUpgradeRegex.test(str);
        }
        const timePattern = /\b(0?[1-9]|1[0-2]):[0-5][0-9]\s?[APap][Mm]\b/g;

        // Use the match method to find all matches
        const times = rqresult.match(timePattern);


        let values = {
            storeInvoiceOA: saleInvoiceExtraction(rqresult).trim().slice(0, 7).trim(),
            saleInvoiceOA: saleInvoiceExtraction(rqresult),
            tenderedDateOA: dateConversion(/\d{2}-[A-Za-z]{3}-\d{4}/.exec(rqresult)[0]),
            timeOA: times[0],
            salesPerson: /Sales Person: ([\w\s]+)/.exec(rqresult)[1].trim(),
            connectionType: checkStringForWords(rqresult)
        }
        return values;
    }

    function extractDiscountInfo(data, skuList) {
        const discountInfo = {};

        skuList.forEach(sku => {
            // Regex for the first format
            const regex1 = new RegExp(`${sku}.*?\\$(\\d+\\.\\d+)\\s\\d+\\.\\d+\\s\\$(\\d+\\.\\d+)\\s\\$(\\d+\\.\\d+)`, 's');
            // Regex for the second format
            const regex2 = new RegExp(`${sku}.*?\\$([\\d\\.]+)\\sList Price\\s\\$([\\d\\.]+)\\sSave\\s\\$([\\d\\.]+)`, 's');

            let match = data.match(regex1);
            if (match) {
                const discount = parseFloat(match[2]);
                discountInfo[sku] = {
                    discount,
                };
            } else {
                match = data.match(regex2);
                if (match) {
                    const discount = parseFloat(match[3]);
                    discountInfo[sku] = {
                        discount,
                    };
                }
            }
        });

        return discountInfo;
    }

    function normalizePhone(phone) {
        return phone.replace(/\D/g, ""); // Remove all non-digit characters
    }

    // Function to check if either phoneNum or tempPhoneNum (normalized) is present in the text
    function checkPhoneInText(obj1, obj2, text) {
        const phoneNumNormalized = obj1 && normalizePhone(obj1);
        const tempPhoneNumNormalized = obj2 && normalizePhone(obj2);

        // Check if the normalized phone numbers appear in the text
        const phoneNumInText = phoneNumNormalized && text.includes(phoneNumNormalized);
        const tempPhoneNumInText = tempPhoneNumNormalized && text.includes(tempPhoneNumNormalized);
        const phoneNumInText1 = obj1 && text.includes(obj1);
        const tempPhoneNumInText1 = obj2 && text.includes(obj2);

        return phoneNumInText || tempPhoneNumInText || phoneNumInText1 || tempPhoneNumInText1 ? true : false;
    }

    const onTypeChange = async (e) => {
        e.preventDefault();
        if ((result || rqresult || apresult).length > 0) {
            const { data } = await getManualSkuList();
            const newData = data[0].manualFile
            const skuList = newData.map(item => item[0]);
            const foundSKUs = skuList.filter(sku => rqresult.includes(sku));
            const attachedAcc = foundSKUs?.map(sku => newData.find(item => item[0] === sku))
            const discountInfo = extractDiscountInfo(rqresult, foundSKUs);

            let totalCost = 0;
            let totalMsrp = 0;
            let totalDiscount = 0;
            attachedAcc.forEach(item => {
                const [sku, /* productName */, cost, msrp] = item;
                totalCost += cost;
                totalMsrp += msrp;
                if (discountInfo[sku] && discountInfo[sku].discount) {
                    totalDiscount += discountInfo[sku].discount;
                }
            })
            let aaData = {
                cost: totalCost,
                msrp: totalMsrp,
                discount: totalDiscount
            }

            let cisDataValues
            let autoPayDataValues
            let rq4DataValues
            if (document.getElementById("autopay").value !== "") {
                cisDataValues = await cisDataExtraction(result);
                autoPayDataValues = await autoPayDataExtraction(apresult, result);
                rq4DataValues = await rq4DataExtraction(rqresult, result);

                const formattedDateStr = dateConversion(rq4DataValues.tenderedDate);

                if (autoPayDataValues.customerName === cisDataValues.customerName && autoPayDataValues.accountNum === cisDataValues.accountNum) {

                    cisDataValues = { ...cisDataValues, autoPayPre: autoPayDataValues.autoPayPre, autoPayPost: autoPayDataValues.autoPayPost }
                } else {

                    toast.error("wrong customer data... pick the right file with same customer name and account number")
                }
                if (rq4DataValues.accountNum === cisDataValues.accountNum || rq4DataValues.imei === cisDataValues.imei || rq4DataValues.accountNum === "DFF") {
                    cisDataValues = { ...cisDataValues, storeInvoice: rq4DataValues.storeInvoice, saleInvoice: rq4DataValues.saleInvoice, tenderedDate: formattedDateStr, time: rq4DataValues.time }
                }
                else {

                    toast.error("wrong customer data... pick the right file with same customer name and account number1")
                }

            } else {
                cisDataValues = await cisDataExtraction(result);
                rq4DataValues = await rq4DataExtraction(rqresult, result);
                const formattedDateStr = dateConversion(rq4DataValues.tenderedDate);

                cisDataValues = { ...cisDataValues, autoPayPre: "N/A", autoPayPost: "N/A" }

                if (rq4DataValues.accountNum === cisDataValues.accountNum || rq4DataValues.imei === cisDataValues.imei || rq4DataValues.accountNum === "DFF") {

                    cisDataValues = { ...cisDataValues, storeInvoice: rq4DataValues.storeInvoice, saleInvoice: rq4DataValues.saleInvoice, tenderedDate: formattedDateStr, time: rq4DataValues.time }
                }

            }
            cisDataValues = { ...cisDataValues, user: username }



            const resultCheck = checkPhoneInText(cisDataValues.phoneNum, cisDataValues.tempPhoneNum, rqresult);

            if ((((rq4DataValues.accountNum === cisDataValues.accountNum || rq4DataValues.storeInvoice) || autoPayDataValues.customerName === cisDataValues.customerName) || rq4DataValues.accountNum === "DFF") && IsSignaturesPresent === true && resultCheck === true) {
                console.log(cisDataValues);
                const { storeInvoice, saleInvoice, tenderedDate, time, customerName, email, accountNum, deposit, retailPrice, phoneProtectionPlan, activatedPromotion, phoneNum, tempPhoneNum, simNumber, imei, amountYouPaid, connectionType, activationDate, phoneMake, planName, planPrice, myBalanceOwing, oneTimeCharges, addons, autoPayPre, autoPayPost, specialistId, storeId, user } = cisDataValues;

                let homePromise = submitCisData({ storeInvoice, saleInvoice, tenderedDate, time, customerName, email, accountNum, deposit, retailPrice, phoneProtectionPlan, activatedPromotion, phoneNum, tempPhoneNum, simNumber, imei, amountYouPaid, connectionType, activationDate, phoneMake, planName, planPrice, myBalanceOwing, oneTimeCharges, addons, autoPayPre, autoPayPost, specialistId, storeId, user });
                homePromise.then(res => {
                }).catch(err => {
                    toast.error(err.response.data.error.error);
                    cisRef.current.value = null;
                    apRef.current.value = null;
                    rqRef.current.value = null;
                })

                toast.promise(homePromise, {
                    loading: 'Uploading...',
                    success: <b>Uploaded Successfully...!</b>,
                    error: <b>Not uploaded</b>

                });
                homePromise.then(() => {
                    cisRef.current.value = null;
                    apRef.current.value = null;
                    rqRef.current.value = null;
                    submitAR({
                        specialistId, tenderedDate, saleInvoice, discountInfo, attachedAcc, connectionType, simNumber, user, aaData, storeInvoice, phoneProtectionPlan, imei
                    })
                })

            } else {
                toast.error('Please upload the correct data or missing signature on CIS file')
            }
        } else {
            toast.error("Please Upload atleast a CIS file and RQ file before clicking Upload Button.")
        }

    };

    const onTypeChangeOnlyAccessoryFile = async (e) => {
        e.preventDefault();
        if (rqAccessoryResult.length > 0) {
            const { data } = await getManualSkuList();
            const newData = data[0].manualFile
            const skuList = newData.map(item => item[0]);
            const foundSKUsOnlyAccessory = skuList.filter(sku => rqAccessoryResult.includes(sku));
            const attachedAccOnlyAccessory = foundSKUsOnlyAccessory?.map(sku => newData.find(item => item[0] === sku))
            const discountInfoOnlyAccessory = extractDiscountInfo(rqAccessoryResult, foundSKUsOnlyAccessory)
            let totalCostOnlyAccessory = 0;
            let totalMsrpOnlyAccessory = 0;
            let totalDiscountOnlyAccessory = 0;
            attachedAccOnlyAccessory.forEach(item => {
                const [sku, /* productName */, cost, msrp] = item;
                totalCostOnlyAccessory += cost;
                totalMsrpOnlyAccessory += msrp;
                if (discountInfoOnlyAccessory[sku] && discountInfoOnlyAccessory[sku].discount) {
                    totalDiscountOnlyAccessory += discountInfoOnlyAccessory[sku].discount;
                }

            })
            let aaDataOnlyAccessory = {
                cost: totalCostOnlyAccessory,
                msrp: totalMsrpOnlyAccessory,
                discount: totalDiscountOnlyAccessory
            }

            const { storeInvoiceOA, saleInvoiceOA, tenderedDateOA, timeOA, salesPerson, connectionType } = await rq4DataExtractionOnlyAccessory(rqAccessoryResult)
            const attachmentRateOnlyAccessory = {
                storeInvoiceOA, saleInvoiceOA, tenderedDateOA, timeOA, aaDataOnlyAccessory, attachedAccOnlyAccessory, salesPerson, username: username || localStorage.getItem('username')
            }
            if (!connectionType) {
                let aRPromiseOnlyAccessory = submitAROnlyAccessory({ attachmentRateOnlyAccessory, tenderedDateOA, saleInvoiceOA })
                aRPromiseOnlyAccessory.then(res => {
                }).catch(err => {
                    toast.error(err.response.data.error.error);
                    rqRefOnlyAccessory.current.value = null;

                })

                toast.promise(aRPromiseOnlyAccessory, {
                    loading: 'Uploading...',
                    success: <b>Uploaded Successfully...!</b>,
                    error: <b>Not uploaded</b>

                });
                aRPromiseOnlyAccessory.then(() => {
                    rqRefOnlyAccessory.current.value = null;
                })
            } else {
                toast.error("Please upload RQ file for Only Accessory")
            }
        } else {
            toast.error("Please Upload a RQ file before clicking Upload Button.")
        }
    }

    return (

        <div className='cisupload'>
            <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
                <div className="close-btn" onClick={toggleSidebar}>
                    ✖
                </div>
                {role?.length > 0 && role === "admin" ? <AdminSidebar /> : <Sidebar />}
            </div>

            <div className='cisupload-container'>
                <div className="navbarcontainer">
                    <button className="hamburger-btn" onClick={toggleSidebar}>
                        ☰
                    </button>
                    <Navbar />
                </div>
                <div className='cisupload-block'>
                    <div className="cis">
                        <Toaster position='top-center' reverseOrder={false} toastOptions={{ duration: 1000 }} gutter={4}></Toaster>
                        <div className='top'>

                            {/* for CIS upload */}
                            <div className='block'>
                                <div className="top-block">
                                    <h4 >CIS</h4>
                                </div>

                                <div className='bottom-block'>
                                    <input onChange={readCisFile} ref={cisRef} accept='.pdf' multiple={false} type="file" id='cis' name='cis' />
                                </div>
                            </div>

                            {/* for Autopay upload */}
                            <div className='block'>
                                <div className="top-block">
                                    <h4 className=''>Autopay</h4>
                                </div>

                                <div className='bottom-block'>
                                    <input onChange={readApFile} ref={apRef} accept='.pdf' multiple={false} type="file" id='autopay' name='autopay' />
                                </div>
                            </div>

                            {/* for RQ4 upload */}
                            <div className='block'>
                                <div className="top-block">
                                    <h4 className=''>RQ4</h4>
                                </div>

                                <div className='bottom-block'>
                                    <input onChange={readRqFile} ref={rqRef} accept='.pdf' multiple={false} type="file" id='rq4' name='rq4' />
                                </div>
                            </div>
                        </div>

                        <div className="bottom">
                            <button onClick={onTypeChange} className='sub-btn' type='submit'>Upload</button>
                        </div>

                    </div>
                    <div className="accossoryBlock">
                        <div className='block'>
                            <div className="top-block">
                                <h4 className=''>Accessory Only</h4>
                            </div>

                            <div className='bottom-block'>
                                <input onChange={readRqOnlyAccessoryFile} ref={rqRefOnlyAccessory} accept='.pdf' multiple={false} type="file" id='rq4' name='rq4' />
                            </div>
                        </div>
                        <div className="bottom">
                            <button onClick={onTypeChangeOnlyAccessoryFile} className='sub-btn' type='submit'>Upload</button>
                        </div>
                    </div>

                    <div className="cis">
                        <div className='top'>
                            <div className='block'>
                                <div className="top-block">
                                    <h4 className=''>Manual CIS Entry Form</h4>
                                    <h4>RQ4</h4>
                                </div>

                                <div className='bottom-block'>
                                    <input onChange={readRQCISForm} ref={rqRefCISForm} accept='.pdf' multiple={false} type="file" id='rq4' name='rq4' />
                                </div>
                                <CisModal
                                    modalIsOpen={modalIsOpen}
                                    closeModal={closeModal}
                                    rqCISFormresult={rqCISFormresult}
                                />
                                <Button variant="contained" color="success" onClick={openModal}>
                                    CIS Form
                                    <FileUploadIcon />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home