import React, { useState, useEffect } from 'react'
import './ChangePassword.scss';
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import { getUser, editPassword } from '../../helper/helper'
import toast, { Toaster } from 'react-hot-toast'

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const username = localStorage.getItem('username');
    const [userData, setUserData] = useState('');
    const [focusedInput, setFocusedInput] = useState(null);

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput(null);
    };
    
    useEffect(() => {
        let userdata = getUser({ username: username });
        userdata.then(res => {
            let apiData = res.data;
            setUserData(apiData)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        } else {
            let passwordConfirmation = editPassword({ username: username, oldPassword: oldPassword, newPassword: newPassword });
            toast.promise(passwordConfirmation, {
                loading: 'Checking...',
                success: <b>Password Changed Successfully...!</b>,
                error: <b> Please try again!</b>
            });
            passwordConfirmation.then(res => {
                setMessage('Password changed successfully.');
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
        }
    };

    return (
        <div className='change-password'>
            {userData.role === 'admin' && <AdminSidebar />}
            {userData.role !== 'admin' && <Sidebar />}

            <div className='change-password-main'>
                <div className='navbarcontainer'>
                    <Navbar />
                </div>
                <div className="change-password-container">
                    <Toaster position='top-center' reverseOrder={false}></Toaster>
                    <h2>Change Password</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="oldPassword">Old Password</label>
                            <input
                                type="password"
                                id="oldPassword"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                onFocus={() => handleFocus('oldPassword')}
                                style={{ borderColor: focusedInput === 'oldPassword' ? 'red' : 'rgb(99 102 241)' }}
                                onBlur={handleBlur}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="newPassword">New Password</label>
                            <input
                                type="password"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                onFocus={() => handleFocus('newPassword')}
                                style={{ borderColor: focusedInput === 'newPassword' ? 'red' : 'rgb(99 102 241)' }}
                                onBlur={handleBlur}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Confirm New Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onFocus={() => handleFocus('confirmPassword')}
                                style={{ borderColor: focusedInput === 'confirmPassword' ? 'red' : 'rgb(99 102 241)' }}
                                onBlur={handleBlur}
                                required
                            />
                        </div>

                        <div className='submitbutton'>
                            <button type="submit">Confirm</button>
                        </div>
                    </form>
                    {message && <p className="message">{message}</p>}
                </div>
            </div>
        </div>
    )
}

export default ChangePassword