import React, { useState, useEffect } from 'react'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'
/* import ReactLoading from 'react-loading'; */
import { getUser, getAllUsersData, getEvent, getEventCisData, deleteEventWholeData } from '../../helper/helper';
import './EventCis.scss'
import EventModal from './EventModal';
import UploadCISModal from './UploadCISModal';
import CISViewModal from './CISViewModal';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

const EventCis = () => {
    const username = localStorage.getItem('username');
    const [userDetail, setUserDetail] = useState(null)
    const [eventApiData, setEventApiData] = useState([])
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [eventinfoforCis, setEventinfoforCis] = useState({})
    const [cisdata, setCisdata] = useState([])

    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
    };

    const closeSidebarOnClickOutside = (e) => {
        if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
            setSidebarVisible(false);
        }
    };

    // Add event listener to handle clicks outside the sidebar
    useEffect(() => {
        document.addEventListener('click', closeSidebarOnClickOutside);
        return () => {
            document.removeEventListener('click', closeSidebarOnClickOutside);
        };
    }, [isSidebarVisible]);

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getUser({ username: username });
            setUserDetail(data)
            try {
                let { data } = await getEventCisData({ startDate, endDate })
                /* const eventNamesSet = new Set(eventApiData.map(event => event.eventName)); */
                /* const groupedData = {};
                data.forEach(cis => {
                    if (eventNamesSet.has(cis.eventName)) {
                        if (!groupedData[cis.eventName]) {
                            groupedData[cis.eventName] = [];
                        }
                        groupedData[cis.eventName].push(cis);
                    }
                });
                console.log(data)
                const resultArray = Object.keys(groupedData).map(eventName => {
                    return { [eventName]: groupedData[eventName] };
                }); */
                const groupedData = data.reduce((acc, item) => {
                    const key = `${item.eventName} ${item.eventDate}`;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});
                setCisdata(groupedData)
            } catch (error) {

            }
        }

        fetchUser();
    }, [username, startDate, endDate, eventApiData])

    const handleSearch = async () => {

        try {
            let { data } = await getEvent({ startDate, endDate });
            let { data: allUsersData } = await getAllUsersData();

            const managerStores = userDetail.stores.split(","); // Splitting manager's stores into an array
            const filteredUsers = allUsersData.filter(user => {
                const userStores = user.stores.split(","); // Splitting user's stores into an array
                // Check if there is any store in the user's list that matches any store in manager's list
                if (user.employmentStatus === 'Active')
                    return userStores.some(store => managerStores.includes(store));
            });
            const checkRepsExist = (addedReps, userdata) => {
                return addedReps.some(rep => userdata.some(user => user.fullname === rep));
            };

            const result = checkRepsExist(data[0].addedReps, filteredUsers);
            if (result && userDetail.role !== "admin") {
                const sortedEvents = data.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
                setEventApiData(sortedEvents);
            } else if (userDetail.role === "admin") {
                const sortedEvents = data.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
                setEventApiData(sortedEvents);
            }
        } catch (error) {
        }
    };

    const colors = ['#f8d7da', '#fff3cd', '#d4edda'];
    const EventTile = ({ event, onUpload, onView, onDelete }) => {

        return (
            <div className='eventlist-tile'>
                <div className='titles'>
                    <div>
                        <p>Name:</p>
                        <div>{event.eventName}</div>
                    </div>
                    <div>
                        <p>Date:</p>
                        <div>{event.eventDate}</div>
                    </div>

                    <div>
                        <p>Reps:</p>
                        <div>
                            {event.addedReps.map((rep, index) => (
                                <span
                                    key={index}
                                    style={{
                                        backgroundColor: colors[index % colors.length],
                                        padding: '5px',
                                        margin: '2px',
                                        display: 'inline-block',
                                        borderRadius: '25px',
                                    }}>
                                    {rep}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='button-row'>
                    {/* <Stack direction="row" spacing={5}>
                
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<UploadFileIcon />}
                            onClick={() => onUpload(event.eventName, event.eventDate)}
                        >
                            Upload
                        </Button>

                     
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<VisibilityIcon />}
                            onClick={() => onView(event.eventName, event.eventDate)}
                        >
                            View
                        </Button>

                       
                        {userDetail?.role === 'admin' ? <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => onView(event.eventName, event.eventDate)}
                        >
                            Delete
                        </Button> : <></>}


                    </Stack> */}
                    <IconButton onClick={() => onUpload(event.eventName, event.eventDate)} sx={{ ml: 2 }} color="primary">
                        <UploadFileIcon />
                    </IconButton>

                    <IconButton onClick={() => onView(event.eventName, event.eventDate)} sx={{ ml: 2 }} color="secondary">
                        <VisibilityIcon />
                    </IconButton>
                    {userDetail?.role === 'admin' ? <IconButton onClick={() => onDelete(event._id, event.eventName)} sx={{ ml: 2 }} color="error">
                        <DeleteIcon />
                    </IconButton> : <></>}
                    {/* <button onClick={() => onUpload(event.eventName, event.eventDate)}>Upload</button> */}
                    {/* <button onClick={() => onView(event.eventName, event.eventDate)}>View</button> */}
                    {/* {userDetail?.role === 'admin' ? <button onClick={() => onDelete(event._id, event.eventName)}>Delete</button> : <></>} */}
                </div>
            </div>
        );
    };


    const handleUpload = (id, date) => {
        setEventinfoforCis({ id, date });
        openModal1()
    };

    const handleView = (id, date) => {
        setEventinfoforCis({ id, date });
        openModal2()
    };

    const handleDelete = (id, name) => {
        if (window.confirm(`Are you sure you want to delete ${name}'s and Activation Sheet?`)) {
            let data = deleteEventWholeData({ id, name });
            if (data) {
                alert("data deleted successfully");
            }
        } else {
            console.log("not done")
        }

    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openModal1 = () => {
        setModalIsOpen1(true);
    };

    const closeModal1 = () => {
        setModalIsOpen1(false);
    };
    const openModal2 = () => {
        setModalIsOpen2(true);
    };

    const closeModal2 = () => {
        setModalIsOpen2(false);
    };


    return (
        <div className="ec-main">
            <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
                <div className="close-btn" onClick={toggleSidebar}>
                    ✖
                </div>
                {userDetail?.role.length > 0 && userDetail?.role === "admin" ? <AdminSidebar /> : <Sidebar />}
            </div>

            <div className="ec-container">
                <div className="navbarcontainer">
                    <button className="hamburger-btn" onClick={toggleSidebar}>
                        ☰
                    </button>
                    <Navbar />
                </div>
                {/* <div className="ec-block"> */}
                <div className="ec-block-container">
                    <div className='filter-menu-list'>
                        <div className='filter-menu'>
                            <div className='filter-datemenu'>
                                <label htmlFor="startDate">From:</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className='filter-datemenu-input'
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label htmlFor="endDate">To:</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className='filter-datemenu-input'
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                                <IconButton onClick={handleSearch} sx={{ ml: 2 }}>
                                    <SearchIcon />
                                </IconButton>
                                {/* <button className='filter-datemenu-button' onClick={handleSearch}>Search</button> */}
                            </div>
                        </div>
                        {userDetail?.role !== "employee" ? (
                            <div className="upload-button-container">
                                <EventModal
                                    modalIsOpen={modalIsOpen}
                                    closeModal={closeModal}
                                />
                                <div className='add-button'>
                                    <button onClick={openModal}>
                                        Add Event
                                    </button>
                                </div>
                            </div>
                        ) : (<></>)}
                    </div>
                    <UploadCISModal
                        modalIsOpen={modalIsOpen1}
                        closeModal={closeModal1}
                        eventinfoforCis={eventinfoforCis}
                    />
                    <CISViewModal
                        modalIsOpen={modalIsOpen2}
                        closeModal={closeModal2}
                        eventinfoforCis={eventinfoforCis}
                        cisdata={cisdata}
                    />
                    <div className='eventlist-container'>
                        {eventApiData.map(event => (
                            <EventTile
                                key={event._id}
                                event={event}
                                onUpload={handleUpload}
                                onView={handleView}
                                onDelete={handleDelete}
                            />
                        ))}
                    </div>
                </div>
                {/*  </div> */}
            </div>
        </div>
    )
}
export default EventCis