import React, { useState, useEffect, useRef } from 'react'
import './Navbar.scss'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { getUser } from '../../helper/helper'

const Navbar = () => {
  const username = localStorage.getItem('username');
  const [isHovered, setIsHovered] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [apiData, setApiData] = useState('');
  const [title, setTitle] = useState('');
  const imageRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    switch (location.pathname) {
      case '/admin':
        setTitle('Dashboard');
        break;
      case '/manager':
        setTitle('Dashboard');
        break;
      case '/employee':
        setTitle('Dashboard');
        break;
      case '/userlist':
        setTitle('View Users');
        break;
      case '/adduser':
        setTitle('Add User');
        break;
      case '/cisupload':
        setTitle('CIS');
        break;
      case '/eventcis':
        setTitle('Event CIS');
        break;
      case '/manualinputdata':
        setTitle('AccessorySKU');
        break;
      case '/scorecard':
        setTitle('Scorecard');
        break;
      case '/refund':
        setTitle('Refunds');
        break;
      case '/targets':
        setTitle('Set Targets');
        break;
      case '/businessreport':
        setTitle('Business P/L');
        break;
      case '/rankingPage':
        setTitle('Ranking');
        break;
      case '/commissionstructure':
        setTitle('Structure');
        break;
      case '/employeeCommissionReport':
        setTitle('Report');
        break;
      case '/attachrate':
        setTitle('Attach Rate');
        break;
      case '/emphours':
        setTitle('Hours');
        break;
      case '/homeinternet':
        setTitle('Home Internet');
        break;
      case '/viewaccessorysold':
        setTitle('View Accessory Sold');
        break;
      case '/viewar':
        setTitle('View Attach Rate');
        break;
      default:
        setTitle('');
    }
  }, [location]);

  /* useEffect(() => {
    const timer = setTimeout(() => {
      alert('Session expired. Please log in again.');
      userLogout() // Redirect to login page
    }, 1 * 60 * 1000); // 10 minutes

    return () => clearTimeout(timer); // Clear timer on component unmount
  }, [userLogout]); */

  const capitalizeFirstLetter = (string) => {
    return apiData?.username === string ? apiData?.fullname : localStorage.getItem('username');
  };
  const handleImageHover = () => {
    setIsHovered(!isHovered);
  };

  const handleImageClick = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };

  const handleDocumentClick = (e) => {
    if (isOptionsVisible && !imageRef.current.contains(e.target)) {
      setIsOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    let userdata = getUser({ username: username || localStorage.getItem('username') });
    userdata.then(res => {
      let apiData = res.data;
      setApiData(apiData)
    })
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsVisible, username]);

  function userLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');

    navigate('/')
  }

  function usernamefromLS() {
    return capitalizeFirstLetter(localStorage.getItem('username'))
  }
  return (
    <div className='navbar'>
      <div className="wrapper">
        <div className="title-nav">
          <h2>{title}</h2>
        </div>

        <div className="items">
          <div className='item'>
            <span>Welcome: {username ? capitalizeFirstLetter(username) : usernamefromLS()}</span>
          </div>
          <div className="item">
            <div
              className={`employee-avatar ${isHovered ? 'hovered' : ''}`}
              onMouseEnter={handleImageHover}
              onMouseLeave={handleImageHover}
              onClick={handleImageClick}
              ref={imageRef}
            >
              <img
                src={apiData?.profilePicture || "https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"}
                alt=""
                className="avatar"
              />
              {isOptionsVisible && (
                <div className="options">
                  <button><Link className='links-icon' to="/editprofile">Edit Profile</Link></button>
                  <button><Link className='links-icon' to="/changepassword">Change Password</Link></button>
                  <button className='links-icon' onClick={userLogout}>Logout{/* <Link className='links-icon' onClick={userLogout}>Logout</Link> */}</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar