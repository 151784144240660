import React, { useState, useRef, useEffect } from 'react'
import './Refund.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import { readCisFile } from '../../components/ReusableFunctions'
import { submitRefundData, deleteRefundRow, getUser } from '../../helper/helper'
import toast, { Toaster } from 'react-hot-toast';
import { DataGrid } from '@mui/x-data-grid';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import RefundFilter from '../../components/RefundFilter'

const Refund = () => {
    const [result, setResult] = useState("");
    const refundRef = useRef(null);
    const username = localStorage.getItem('username');
    const [refundData, setRefundData] = useState([])
    const [arrIds, setArrIds] = useState([]);
    const [role, setRole] = useState('')
    const [apiData, setApiData] = useState([])

    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
    };

    const closeSidebarOnClickOutside = (e) => {
        if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
            setSidebarVisible(false);
        }
    };

    // Add event listener to handle clicks outside the sidebar
    useEffect(() => {
        document.addEventListener('click', closeSidebarOnClickOutside);
        return () => {
            document.removeEventListener('click', closeSidebarOnClickOutside);
        };
    }, [isSidebarVisible]);

    useEffect(() => {
        let dataUser = getUser({ username: username });
        dataUser.then(res => {
            let apiData = res.data;
            setRole(apiData?.role)
            setApiData(apiData)
        })
    }, [username])
    async function readPdfFile(event) {
        await readCisFile(event, setResult)
    }

    function dateConversion(date) {
        // Parse the input date string using the Date constructor
        const parsedDate = new Date(date);

        // Format the parsed date in the "YYYY-MM-DD" format
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(parsedDate.getDate()).padStart(2, "0");

        // Create the formatted date string
        const formattedDateStr = `${year}-${month}-${day}`;
        return formattedDateStr
    }

    function dataExtractor(text) {

        const refundInvoiceRegex = /Refund Invoice : (\w+)/;
        const originalInvoiceRegex = /Original Invoice:(\w+)/;
        const tenderedOnRegex = /Tendered On (\d{2}-[A-Za-z]{3}-\d{4}) | Tendered On: (\d{2}-[A-Za-z]{3}-\d{4})/;
        const customerNameRegex = /Customer Information (\w+ \w+) | Bill To: (\w+ \w+)/;
        const simRegex = /SIM Tracking # (\w+) | SIM (\d+)/;
        const imeiRegex = /Tracking # (\d+)/;
        const phoneNumberRegex = /Postpaid New MyTab (\d+)/;

        const imeiMatch = text.match(imeiRegex);
        const phoneNumberMatch = text.match(phoneNumberRegex);
        console.log(text.match(phoneNumberRegex))

        const refundInvoice = text.match(refundInvoiceRegex)[1];
        const originalInvoice = text.match(originalInvoiceRegex)[1];
        const tenderedOn = dateConversion(text?.match(tenderedOnRegex)[1] ? text?.match(tenderedOnRegex)[1] : text?.match(tenderedOnRegex)[2]);
        const customerName = text.match(customerNameRegex)[1] ? text.match(customerNameRegex)[1] : text.match(customerNameRegex)[2];
        const sim = text.match(simRegex) !== null ? (text.match(simRegex)[1] ? text?.match(simRegex)[1] : text?.match(simRegex)[2]) : 0;
        const sim1 = sim !== 0 ? sim : "No sim";


        const imei = imeiMatch ? imeiMatch[1] : null;
        const phoneNumber = phoneNumberMatch !== null ? (phoneNumberMatch[1] ? phoneNumberMatch[1] : phoneNumberMatch[2]) : "No phone number";
        const user = username;
        const storeInvoice = refundInvoice.slice(0, 7);

        return {
            refundInvoice, originalInvoice, tenderedOn, customerName, sim1, imei, phoneNumber, user, storeInvoice
        }
    }

    function onTypeChange(e) {
        e.preventDefault();

        const { refundInvoice, originalInvoice, tenderedOn, customerName,
            sim1, imei, phoneNumber, user, storeInvoice } = dataExtractor(result);

        console.log(refundInvoice, originalInvoice, tenderedOn, customerName,
            sim1, imei, phoneNumber, user, storeInvoice)

        let refundPromise = submitRefundData({
            refundInvoice, originalInvoice, tenderedOn, customerName,
            sim1, imei, phoneNumber, user, storeInvoice
        })
        toast.promise(refundPromise, {
            loading: 'Uploading...',
            success: <b>Uploaded Successfully...!</b>,
            error: <b>Not uploaded</b>
        })

        refundPromise.then(() => {
            refundRef.current.value = null;
        })

    }

    const updateTableData = (data) => {
        let newData = [];
        if (apiData?.role === 'employee') {
            data?.forEach(user => {
                if (user.user === username) {
                    newData.push(user)
                }
            })
            setRefundData(newData)
        } else if (apiData?.role === 'manager') {
            let stores = apiData?.stores.split(',')
            data?.forEach(user => {
                let exist = stores?.some(store => store === user.storeInvoice)
                if (exist) {
                    newData.push(user)
                }
                setRefundData(newData)
            })
        } else if (apiData?.role === 'admin') {
            setRefundData(data)
        }
    }

    const columns = [
        { field: 'refundInvoice', headerName: 'Refund Invoice', width: 150 },
        { field: 'originalInvoice', headerName: 'Original Invoice', width: 150 },
        { field: 'tenderedOn', headerName: 'Tendered On', width: 150 },
        { field: 'customerName', headerName: 'Customer Name', width: 150 },
        { field: 'sim', headerName: 'SIM Number', width: 150 },
        { field: 'imei', headerName: 'IMEI Number', width: 150 },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
        { field: 'user', headerName: 'Employee', width: 150 },
        { field: 'storeInvoice', headerName: 'Store Invoice', width: 150 },
    ]
    const rows = refundData.map((row) => ({
        _id: row._id,
        refundInvoice: row.refundInvoice,
        originalInvoice: row.originalInvoice,
        tenderedOn: row.tenderedOn,
        customerName: row.customerName,
        sim: row.sim,
        imei: row.imei,
        phoneNumber: row.phoneNumber,
        user: row.user,
        storeInvoice: row.storeInvoice,
    }))
    async function deleteRefundData(id) {

        if (window.confirm(`Are you sure you want to delete ${id}'s data?`)) {
            let data = await deleteRefundRow({ id });
            if (data) {
                alert("data deleted successfully");
            }

        }
    }

    return (
        <div className="refund">
            
            <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
                <div className="close-btn" onClick={toggleSidebar}>
                    ✖
                </div>
                {role?.length > 0 && role === "admin" ? <AdminSidebar /> : <Sidebar />}
            </div>

            <div className="refund-container">
                <div className="navbarcontainer">
                    <button className="hamburger-btn" onClick={toggleSidebar}>
                        ☰
                    </button>
                    <Navbar />
                </div>
                {/*  <div className="refund-block"> */}

                <div className="refund-block-container">
                    <div className='filter-menu'>
                        <RefundFilter onDataLoaded={updateTableData} />
                    </div>

                    <div className='bot'>
                        <div className="tablecontainer" >
                            <div className="topbuttons">
                                <button onClick={() => deleteRefundData(arrIds)} className='edit-btn' type='submit'>Delete</button>
                            </div>
                            <div className="bottomtable" style={{ height: 350, width: '100%' }}>
                                <DataGrid
                                    getRowId={(row) => row._id}
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    onRowSelectionModelChange={(ids) => {
                                        setArrIds(ids)
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='top'>

                        <div className='refund-title-div'>
                            <h2 className='refund-title'>Upload your Refunds here...</h2>
                        </div>

                        <div className='refund-bottom-block'>
                            <input onChange={readPdfFile}
                                className="file-input" ref={refundRef} accept='.pdf'
                                multiple={false} type="file" id='cis' name='cis' />
                            <label htmlFor="cis" className="file-label">Choose file</label>
                        </div>
                        <div className='bottom-button'>
                            <button onClick={onTypeChange} className="upload-button">Upload</button>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default Refund