import React, { useState, useEffect } from 'react'
import './RankingPage.scss'
import { getAttachRate, getOnlyAccessory, getUser, getFilterdByDate, getAllUsersData, getPandLData,/*  getManualInputData */ } from '../../helper/helper';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import ReactLoading from 'react-loading';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const RankingPage = () => {
    const username = localStorage.getItem('username');
    const [userList, setuserList] = useState([])
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
    };

    const closeSidebarOnClickOutside = (e) => {
        if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
            setSidebarVisible(false);
        }
    };

    // Add event listener to handle clicks outside the sidebar
    useEffect(() => {
        document.addEventListener('click', closeSidebarOnClickOutside);
        return () => {
            document.removeEventListener('click', closeSidebarOnClickOutside);
        };
    }, [isSidebarVisible]);

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getUser({ username: username });
            setUserDetail(data)
        }
        fetchUser();
    }, [username])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getAllUsersData();
            setuserList(data)
        }
        fetchUser();
    }, [username])


    const currentDate = new Date();
    // Calculate the first day of the month
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // Calculate the last day of the month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    // Format the first and last day as strings (YYYY-MM-DD)
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString);
    const [plData, setPlData] = useState(null)
    /* const [accessoryData, setAccessoryData] = useState(null); */
    const [cisdata, setCisdata] = useState(null);
    const [sortedStoreData, setSortedStoreData] = useState([]);
    const [sortedEmpData, setSortedEmpData] = useState([]);
    const [sortedStoreAcc, setSortedStoreAcc] = useState([])
    const [sortedEmpAcc, setSortedEmpAcc] = useState([])
    const [sortedStorePandL, setSortedStorePandL] = useState([])
    const [selectedOption, setSelectedOption] = useState('');
    const [userdata, setUserdata] = useState([]);
    const [userDetail, setUserDetail] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [AAMSRP, setAAMSRP] = useState([])
    const [OAMSRP, setOAMSRP] = useState([])

    const handleSearch = async () => {
        setSelectedOption('')
        setSortedStoreAcc([])
        setSortedEmpAcc([]);
        setSortedStorePandL([]);
        setSortedStoreData([]);
        setSortedEmpData([]);
        setIsLoading(true);
        const today = new Date(endDate);
        const prevMonth = today.toLocaleString('default', { month: 'long' });
        const prevYear = today.getFullYear();
        const endDate1 = prevMonth + ' ' + prevYear

        try {
            if (userDetail.role === 'manager') {
                let finalArray = []
                userDetail?.stores?.split(',').forEach(store => {
                    userList.forEach(user => {
                        let exist = user.stores.split(',').some(item => item === store)
                        if (exist && user.role !== 'manager') finalArray.push(user)
                    })
                })
                setUserdata(finalArray);
            } else if (userDetail.role === 'admin') {
                setUserdata(userList);
            }

        } catch (error) {
            return error
        }

        try {
            let { data } = await getFilterdByDate({ startDate, endDate });

            if (userDetail.role === 'manager') {
                let finalArray = []
                data?.forEach(val => {
                    let isExist = userDetail.stores.split(',').some(item => item === val.storeInvoice)
                    if (isExist) finalArray.push(val)
                })
                setCisdata(finalArray)
            } else if (userDetail.role === 'admin') {
                setCisdata(data)
            }

        } catch (error) {
            return error
        }

        try {

            if (userDetail.role === 'manager') {
                setPlData([]);
            } else if (userDetail.role === 'admin') {
                let { data } = await getPandLData(endDate1);
                setPlData(data);
            }

        } catch (error) {
            return error
        }

        try {
            const { data } = await getAttachRate({ startDate, endDate });
            const removeDuplicatesByInvoice = (array) => {
                const seenInvoices = new Set();
                return array.filter(item => {
                    if (!item.saleInvoice) {
                        return true; // Keep objects without saleInvoice
                    }
                    const duplicate = seenInvoices.has(item.saleInvoice);
                    seenInvoices.add(item.saleInvoice);
                    return !duplicate;
                });
            };
            const uniqueArray = removeDuplicatesByInvoice(data);
            if (uniqueArray?.length > 0) {
                let { data } = await getUser({ username: username });
                if (data?.role === 'manager') {
                    let finalArray = []
                    data?.stores?.split(',').forEach(store => {
                        userList.forEach(user => {
                            let exist = user.stores.split(',').some(item => item === store)
                            if (exist) {
                                if (!finalArray.includes(user)) {
                                    finalArray.push(user)
                                }
                            }
                        })
                    })
                    let storesList = data?.stores?.split(',')
                    let filterAR = []
                    storesList.forEach(store => {
                        uniqueArray.forEach(obj => {
                            const { storeInvoice } = obj;
                            if (storeInvoice === store) {
                                filterAR.push(obj)
                            }
                        })
                    })
                    dataProcess(filterAR)
                } else if (data?.role === 'admin') {
                    dataProcess(uniqueArray)
                } else if (data?.role === 'employee') {
                    let filterAR = []
                    uniqueArray.forEach(obj => {
                        if (obj.user === data?.username) {
                            filterAR.push(obj)
                        }
                    })
                    dataProcess(filterAR)
                }
            }

            function dataProcess(uniqueArray) {
                const groupedBySpecialist = {};
                const groupedByStore = {};
                uniqueArray.forEach(obj => {
                    const { user, ...rest } = obj;
                    if (!groupedBySpecialist[user]) {
                        groupedBySpecialist[user] = [rest];
                    } else {
                        groupedBySpecialist[user].push(rest);
                    }
                    const { storeInvoice, ...rest1 } = obj;
                    if (!groupedByStore[storeInvoice]) {
                        groupedByStore[storeInvoice] = [rest1];
                    } else {
                        groupedByStore[storeInvoice].push(rest1);
                    }
                });
                function getMSRP(data) {
                    const groupedBySpecialistmerged = {};
                    for (let key in data) {
                        let hupSum = { sf: 0, };
                        let actSum = { sf: 0, };

                        data[key].forEach(obj => {
                            const { aaData, connectionType, simNumber } = obj;
                            const { msrp, discount } = aaData;
                            const sf = msrp - discount;
                            if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A") {
                                hupSum.sf += sf;
                            } else if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A") {
                                actSum.sf += sf;
                            }
                        });
                        groupedBySpecialistmerged[key] = {
                            msrp: actSum.sf + hupSum.sf
                        }
                    }
                    return groupedBySpecialistmerged
                }
                const empData = getMSRP(groupedBySpecialist)
                const storeData = getMSRP(groupedByStore)
                setAAMSRP([storeData, empData])
            }
        } catch (error) {

        }

        const removeDuplicatesByInvoice = (array) => {
            const seenInvoices = new Set();
            return array.filter(item => {
                if (!item.saleInvoiceOA) {
                    return true; // Keep objects without saleInvoice
                }
                const duplicate = seenInvoices.has(item.saleInvoiceOA);
                seenInvoices.add(item.saleInvoiceOA);
                return !duplicate;
            });
        };

        try {
            function getMSRP(data) {
                const groupedByAccmerged = {};
                for (let key in data) {
                    let accSum = { sf: 0, };
                    data[key].forEach(obj => {
                        const { aaDataOnlyAccessory } = obj;
                        const { msrp: msrpOA, discount: discountOA } = aaDataOnlyAccessory;
                        const sf = msrpOA - discountOA
                        accSum.sf += sf
                    })
                    groupedByAccmerged[key] = {
                        OAMsrp: accSum.sf,
                    }
                }
                return groupedByAccmerged
            }


            let { data } = await getOnlyAccessory({ startDate, endDate });
            let filtered = [];
            data.forEach(obj => {
                filtered.push(obj.attachmentRateOnlyAccessory)
            })

            const uniqueArray = removeDuplicatesByInvoice(filtered);
            if (uniqueArray.length > 0) {
                let { data } = await getUser({ username: username });

                if (data?.role === 'manager') {

                    let storesList = data?.stores?.split(',')
                    let matchingInvoices = uniqueArray.filter(item => {
                        // Extract the prefix part of the saleInvoiceOA
                        let prefix = '';
                        if (item.saleInvoiceOA !== '') {
                            prefix = item?.saleInvoiceOA.match(/^[a-zA-Z]+/)[0];
                        }
                        // Check if the extracted prefix matches any prefix in array2
                        return storesList.some(prefixInArray => prefix === prefixInArray);
                    }).map(item => item);

                    dataProcess(matchingInvoices)
                } else if (data?.role === 'admin') {

                    dataProcess(uniqueArray)
                } else if (data?.role === 'employee') {
                    let filterAR = []
                    uniqueArray.forEach(obj => {
                        if (obj.username === data?.username) {
                            filterAR.push(obj)
                        }
                    })
                    dataProcess(filterAR)
                }
            }


            function dataProcess(filtered) {
                let oAEmpList = {};
                let oAStoreList = {};
                filtered.forEach(obj => {
                    const { username, ...rest } = obj;

                    if (!oAEmpList[username]) {
                        oAEmpList[username] = [rest]
                    } else {
                        oAEmpList[username].push(rest)
                    }
                })
                filtered.forEach(obj => {
                    const { saleInvoiceOA, ...rest } = obj;
                    const storeInvoice = saleInvoiceOA.slice(0, 7);
                    if (!oAStoreList[storeInvoice]) {
                        oAStoreList[storeInvoice] = [rest]
                    } else {
                        oAStoreList[storeInvoice].push(rest)
                    }
                })
                const empMSRP = getMSRP(oAEmpList);
                const storeMSRP = getMSRP(oAStoreList);
                setOAMSRP([empMSRP, storeMSRP])
            }




        } catch (error) {

        }
    };
    if (isLoading && userdata?.length > 0 && cisdata?.length > 0 /* && accessoryData?.length > 0 */) {

        setIsLoading(false);
        setSelectedOption('Total Act')
        setSortedStoreAcc([])
        setSortedEmpAcc([]);
        setSortedStorePandL([]);

        let filterStores = []
        let storesList = cisdata?.map(store => store.storeInvoice);
        storesList?.forEach(val => {
            let isExist = filterStores.some(store => store === val);
            if (!isExist) filterStores.push(val);
        })

        let filterEmployee = []
        let employeeList = cisdata?.map(emp => emp.user);
        employeeList?.forEach(val => {
            let isExist = filterEmployee.some(employee => employee === val);
            if (!isExist) filterEmployee.push(val);
        })

        let storeCisData = []
        filterStores.forEach(store => {
            let list = [];
            cisdata.forEach(storeid => {
                if (storeid.storeInvoice === store) {
                    list.push(storeid);
                }
            });
            storeCisData[store] = cistoCisCount(list)
        })
        let empCisData = []
        filterEmployee.forEach(emp => {
            let list = [];
            cisdata.forEach(storeid => {
                if (storeid.user === emp) {
                    list.push(storeid);
                }
            });
            empCisData[emp] = cistoCisCount(list);
        })
        rankingComputationCIS(storeCisData, empCisData, 'Total Act')
    }
    const types = ['Total Act', 'Postpaid', 'Prepaid', 'Hup', 'Addon', 'With Device', 'Autopay', 'Accessorries', 'P/L'];

    const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
    };

    function cistoCisCount(employeeData) {
        let data = { postCount: 0, preCount: 0, hupCount: 0, autopayCount: 0, withDeviceCount: 0, pppCount: 0, addonsCount: 0 }


        employeeData?.map((item) => {
            if (item.phoneProtectionPlan === "Accepted") {
                data.pppCount++;
            }
            if (item.simNumber !== "N/A" && item.connectionType === "Postpaid") {
                data.postCount++;
            }
            if (item.connectionType === "Prepaid") {
                data.preCount++;
            }
            if (item.simNumber === "N/A" && item.imei !== "N/A") {
                data.hupCount++;
            }
            if (item.autoPayPre === "YES" || item.autoPayPost === "YES" || item.autoPayPre === "successfully" || item.autoPayPre === "successfully for Pre - Authorized Payments" || item.autoPayPost === "successfully for Pre - Authorized Payments") {
                data.autopayCount++;
            }
            if (item.simNumber !== "N/A" && item.imei !== "N/A") {
                data.withDeviceCount++;
            }
            if (item.addons !== "") {
                data.addonsCount++;
            }
            return true;
        })
        return data;
    }

    function rankingComputationCIS(StoreData, empCisData, option) {

        let sortedData = [];
        let sortedEmpData = [];
        switch (option) {
            case 'Total Act':
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store].postCount + StoreData[store].preCount
                })).sort((a, b) => b.count - a.count);

                sortedEmpData = Object.keys(empCisData).map(emp => ({
                    name: emp,
                    count: empCisData[emp].postCount + empCisData[emp].preCount
                })).sort((a, b) => b.count - a.count);
                break;

            case 'Prepaid':
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store].preCount
                })).sort((a, b) => b.count - a.count);

                sortedEmpData = Object.keys(empCisData).map(emp => ({
                    name: emp,
                    count: empCisData[emp].preCount
                })).sort((a, b) => b.count - a.count);
                break;

            case 'Postpaid':
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store].postCount
                })).sort((a, b) => b.count - a.count);

                sortedEmpData = Object.keys(empCisData).map(emp => ({
                    name: emp,
                    count: empCisData[emp].postCount
                })).sort((a, b) => b.count - a.count);
                break;

            case 'Hup':
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store].hupCount
                })).sort((a, b) => b.count - a.count);

                sortedEmpData = Object.keys(empCisData).map(emp => ({
                    name: emp,
                    count: empCisData[emp].hupCount
                })).sort((a, b) => b.count - a.count);
                break;

            case 'Addon':
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store].addonsCount
                })).sort((a, b) => b.count - a.count);

                sortedEmpData = Object.keys(empCisData).map(emp => ({
                    name: emp,
                    count: empCisData[emp].addonsCount
                })).sort((a, b) => b.count - a.count);
                break;

            case 'With Device':
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store].withDeviceCount
                })).sort((a, b) => b.count - a.count);

                sortedEmpData = Object.keys(empCisData).map(emp => ({
                    name: emp,
                    count: empCisData[emp].withDeviceCount
                })).sort((a, b) => b.count - a.count);
                break;

            case 'Autopay':
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store].autopayCount
                })).sort((a, b) => b.count - a.count);

                sortedEmpData = Object.keys(empCisData).map(emp => ({
                    name: emp,
                    count: empCisData[emp].autopayCount
                })).sort((a, b) => b.count - a.count);
                break;
            default:
                sortedData = Object.keys(StoreData).map(store => ({
                    name: store,
                    count: StoreData[store][option.toLowerCase() + 'Count']
                }));
        }
        setSortedStoreData(sortedData)
        setSortedEmpData(sortedEmpData)
    }

    const handleTypeChange = (event) => {

        event.preventDefault();
        const { value } = event.target;
        setSelectedOption(value)

        if (value === 'Total Act' || value === 'Postpaid' || value === 'Prepaid' || value === 'Hup' || value === 'Addon' || value === 'With Device' || value === 'Autopay') {
            setSortedStoreAcc([])
            setSortedEmpAcc([]);
            setSortedStorePandL([]);
            let filterStores = []
            let storesList = cisdata?.map(store => store.storeInvoice);
            storesList?.forEach(val => {
                let isExist = filterStores.some(store => store === val);
                if (!isExist) filterStores.push(val);
            })

            let filterEmployee = []
            let employeeList = cisdata?.map(emp => emp.user);
            employeeList?.forEach(val => {
                let isExist = filterEmployee.some(employee => employee === val);
                if (!isExist) filterEmployee.push(val);
            })

            let storeCisData = []
            filterStores.forEach(store => {
                let list = [];
                cisdata.forEach(storeid => {
                    if (storeid.storeInvoice === store) {
                        list.push(storeid);
                    }
                });
                storeCisData[store] = cistoCisCount(list)
            })
            let empCisData = []
            filterEmployee.forEach(emp => {
                let list = [];
                cisdata.forEach(storeid => {
                    if (storeid.user === emp) {
                        list.push(storeid);
                    }
                });
                empCisData[emp] = cistoCisCount(list);
            })
            rankingComputationCIS(storeCisData, empCisData, value)


        } else if (value === 'Accessorries') {
            setSortedStoreData([]);
            setSortedEmpData([]);
            setSortedStorePandL([]);
            const combineArrays = (arr1, arr2) => {
                const combined = {};

                arr1.forEach(obj => {
                    for (const [key, value] of Object.entries(obj)) {
                        if (combined[key]) {
                            combined[key] += value.msrp;
                        } else {
                            combined[key] = value.msrp;
                        }
                    }
                });

                arr2.forEach(obj => {
                    for (const [key, value] of Object.entries(obj)) {
                        if (combined[key]) {
                            combined[key] += value.OAMsrp;
                        } else {
                            combined[key] = value.OAMsrp;
                        }
                    }
                });

                return combined;
            };

            const combinedResult = combineArrays(AAMSRP, OAMSRP);

            let filterStores = []
            let storesList = cisdata?.map(store => store.storeInvoice);
            storesList?.forEach(val => {
                let isExist = filterStores.some(store => store === val);
                if (!isExist) filterStores.push(val);
            })

            let filterEmployee = []
            let employeeList = cisdata?.map(emp => emp.user);
            employeeList?.forEach(val => {
                let isExist = filterEmployee.some(employee => employee === val);
                if (!isExist) filterEmployee.push(val);
            })

            let storeAccData = []
            filterStores.forEach(store => {
                let list = "";
                Object.keys(combinedResult)?.forEach(acc => {
                    if (acc === store) {
                        list = combinedResult[acc]
                    }
                });
                /* accessoryData.forEach(acc => {
                    if (acc.storeid === store) {
                        list.push(acc);
                    }
                }); */
                storeAccData[store] = list
            })
            let empAccData = []
            filterEmployee.forEach(emp => {
                let list = "";

                Object.keys(combinedResult)?.forEach(acc => {
                    if (acc === emp) {
                        list = combinedResult[acc]
                    }
                });
                /* accessoryData.forEach(acc => {
                    if (acc.employee === emp) {
                        list.push(acc);
                    }
                }); */
                empAccData[emp] = list
            })
            let storeAccData1 = []
            storeAccData1 = Object.keys(storeAccData).map(store => ({
                name: store,
                count: parseFloat(storeAccData[store].toFixed(2))
            })).sort((a, b) => b.count - a.count)

            let empAccData1 = []
            empAccData1 = Object.keys(empAccData).map(emp => ({
                name: emp,
                count: parseFloat(empAccData[emp].toFixed(2))
            })).sort((a, b) => b.count - a.count)

            let storeAccData2 = []
            storeAccData2 = Object.keys(storeAccData1).map(store => ({
                name: storeAccData1[store].name,
                count: storeAccData1[store].count.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }))
            let empAccData2 = []
            empAccData2 = Object.keys(empAccData1).map(emp => ({
                name: empAccData1[emp].name,
                count: empAccData1[emp].count.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }))
            setSortedStoreAcc(storeAccData2);
            setSortedEmpAcc(empAccData2);

        } else if (value === 'P/L') {
            setSortedStoreData([])
            setSortedEmpData([])
            setSortedStoreAcc([])
            setSortedEmpAcc([])
            const plDataNew = []
            for (let key1 in plData) {
                for (let key in plData[key1].plData) {
                    let item = '';
                    Object.keys(storeNameList).forEach(item1 => {
                        if (storeNameList[item1] === key) {
                            item = item1
                        };
                    })
                    const totalRev = plData[key1].plData[key].revenue['Total Sales']
                    const totalExp = plData[key1].plData[key].expense['Total Expense']
                    const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);
                    plDataNew[item] = parseFloat(netProfitLoss)
                }
            }
            let plDate1 = []
            plDate1 = Object.keys(plDataNew).map(store => ({
                name: store,
                count: plDataNew[store]
            })).sort((a, b) => b.count - a.count)
            let plDate2 = []
            plDate2 = Object.keys(plDate1).map(store => ({
                name: plDate1[store].name,
                count: plDate1[store].count.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }))

            setSortedStorePandL(plDate2)
        }
    }

    const capitalizeFirstLetter = (string) => {
        let user = []
        for (let key in userdata) {
            if (userdata[key].username === string) {
                user.push(userdata[key].fullname)
            }
        }
        return user.length > 0 ? user[0] : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().slice(0, -11)
    };

    function formatNegativeNumber(number) {
        // Remove any non-digit characters and convert to a number
        const numericValue = parseFloat(number.replace(/[^0-9.-]/g, ''));

        // Check if the number is negative
        if (numericValue < 0) {
            // Format the absolute value with commas for thousands separator
            const formattedNumber = Math.abs(numericValue).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            // Return the formatted number with parentheses
            return `(${formattedNumber})`;
        }
        // If the number is not negative, return it as is
        return number;
    }

    const StoreTable = ({ storeData, empData, dollersign, negative }) => {
        return (
            <>
                <div className='storeTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Store</th>
                                <th>Numbers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storeData.map((store, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{storeNameList[store.name]}</td>
                                    <td>
                                        <span>{dollersign === true ? `${"$"}` : ''}</span>
                                        <span>{negative === true ? formatNegativeNumber(store.count) : store.count}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="empTable">
                    <table>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Employee</th>
                                <th>Numbers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {empData?.map((store, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{capitalizeFirstLetter(store.name)}</td>
                                    <td>
                                        <span>{dollersign === true ? `${"$"}` : ''}</span>
                                        <span>{store.count}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    return (
        <div className='rp-main'>
            <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
                <div className="close-btn" onClick={toggleSidebar}>
                    ✖
                </div>
                {userDetail?.role.length > 0 && userDetail?.role === "admin" ? <AdminSidebar /> : <Sidebar />}
            </div>

            <div className="rp-container">
                <div className="navbarcontainer">
                    <button className="hamburger-btn" onClick={toggleSidebar}>
                        ☰
                    </button>
                    <Navbar />
                </div>
                {/* <div className="rp-block"> */}
                <div className="rp-block-container">
                    <div className='filter-menu-list'>
                        <div className='filter-menu'>
                            <div className='filter-datemenu'>
                                <label htmlFor="startDate">From:</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className='filter-datemenu-input'
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label htmlFor="endDate">To:</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className='filter-datemenu-input'
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                                <IconButton onClick={handleSearch} sx={{ ml: 2 }}>
                                    <SearchIcon />
                                </IconButton>
                            </div>
                        </div>

                        {cisdata?.length > 0 && (

                            <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    {/* <label htmlFor='filter-type'>Ranking by:</label> */}
                                    <FormControl fullWidth>
                                        <InputLabel>Rank by</InputLabel>
                                        <Select
                                            id="employeeFilter"
                                            value={selectedOption}
                                            onChange={handleTypeChange}
                                            className='filter-datemenu-select'
                                            label="Rank by"
                                        >
                                            {types.map((type, index) => (
                                                <MenuItem key={index} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        {isLoading ? (
                            <div className="loading-overlay">
                                <ReactLoading type='spinningBubbles' color='white' height={'10%'} width={'10%'} />
                            </div>
                        ) : (
                            <>
                                {/* <div className='rp-typeFilter'>
                                        <label htmlFor='filter-type'>Ranking by:</label>
                                        <select id='filter-type' onChange={handleTypeChange} value={selectedOption}>
                                            <option value="">Select a Type</option>
                                            {types.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))}
                                        </select>
                                    </div> */}
                                {cisdata?.length > 0 && (

                                    <div className="datatable">
                                        {(selectedOption !== 'Accessorries' && selectedOption !== 'P/L') && <StoreTable storeData={sortedStoreData} empData={sortedEmpData} />}
                                        {selectedOption === 'Accessorries' && <StoreTable storeData={sortedStoreAcc} empData={sortedEmpAcc} dollersign={true} />}
                                        {selectedOption === 'P/L' && <StoreTable storeData={sortedStorePandL} negative={true} dollersign={true} />}
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default RankingPage