import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import './AdminHome.scss'
import AdminDashboard from '../../components/AdminDashboard/AdminDashboard'

const AdminHome = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('click', closeSidebarOnClickOutside);
    return () => {
      document.removeEventListener('click', closeSidebarOnClickOutside);
    };
  }, [isSidebarVisible]);

  const toggleSidebar = () => {
    setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
  };

  const closeSidebarOnClickOutside = (e) => {
    if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
      setSidebarVisible(false);
    }
  };

  return (
    <>
      <div className='admin'>
        <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
          <div className="close-btn" onClick={toggleSidebar}>
            ✖
          </div>

          <AdminSidebar />
        </div>
        <div className="admincontainer">
          <div className="navbarcontainer">
            <button className="hamburger-btn" onClick={toggleSidebar}>
              ☰
            </button>
            <Navbar />
          </div>
          <div className="admindashboard">
            <AdminDashboard />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminHome